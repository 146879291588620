import { navigate } from 'gatsby';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getConfigRecords, login, sendLivestreamEmails, updateConfigRecord } from '../app/api';
import { keyLabel } from '../app/utils';
import Button from '../components/Button';
import Form from '../components/Form';
import Modal from '../components/Modal';

function LoginModal({ onLogin }) {
  const { register, handleSubmit } = useForm();
  const [message, setMessage] = useState(null);

  function onSubmit(data) {
    login(data)
      .then(onLogin)
      .catch(e => setMessage(e.message));
  }

  return (
    <Modal open>
      <Modal.Content>
        <h2>Admin Login</h2>
        {message && (
          <p data-error className="message">
            {message}
          </p>
        )}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Input label="Username" name="username" inputRef={register({ required: true })} />
          <Form.Password label="Password" name="password" inputRef={register({ required: true })} />
          <Modal.Actions>
            <Button primary content="Login" />
            <Button content="View Site" onClick={() => navigate('/')} />
          </Modal.Actions>
        </Form>
      </Modal.Content>
    </Modal>
  );
}

function getComponent(type) {
  switch (type) {
    case 'text':
      return Form.TextArea;
    case 'boolean':
      return Form.Checkbox;
    case 'date':
      return Form.DateTime;
    default:
      return Form.Input;
  }
}

function ConfigRecord({ config, inputRef, control }) {
  const Component = getComponent(config.fields.type);
  const { key, type } = config.fields;

  let defaultValue = config.fields[type];

  return (
    <Component
      inputRef={inputRef}
      control={control}
      defaultValue={defaultValue}
      name={key}
      label={keyLabel(key)}
    />
  );
}

function ConfigModal({ config, onSubmit, onSendEmails }) {
  const { register, control, handleSubmit, watch, formState } = useForm();

  let facebookEmbedCode = watch('facebookEmbedCode');
  const { isDirty } = formState;

  let facebookEmbed = useMemo(() => {
    let element =
      facebookEmbedCode ?? config?.find(c => c?.fields?.key === 'facebookEmbedCode')?.fields?.text;
    return /src=\"(((?!=\").)*)\"/.exec(element || '')?.[1];
  }, [facebookEmbedCode]);

  return (
    <>
      <Modal open>
        <Modal.Content>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <h2>Site Config</h2>
            {config.map(config => (
              <ConfigRecord key={config.id} control={control} inputRef={register} config={config} />
            ))}
            <Modal.Actions>
              <Button primary content="Update" />
              <Button type='button' content="Send livestream email" onClick={onSendEmails} disabled={isDirty} />
            </Modal.Actions>
          </Form>
          <VideoPreview src={facebookEmbed} />
        </Modal.Content>
      </Modal>
    </>
  );
}

export default function AdminPage() {
  const [user, setUser] = useState();
  const [config, setConfig] = useState([]);
  const [message, setMessage] = useState(null);

  function updateConfig(data) {
    let promises = config.map(record => {
      const { key, type } = record.fields;
      if (data[key] === record.fields[type]) return;
      return updateConfigRecord(record.id, type, data[key]);
    });

    Promise.all(promises)
      .then(getConfigRecords)
      .then(setConfig)
      .catch(e => setMessage(e.message))
      .then(() => setMessage('The Config has been saved'));
  }

  function sendEmails() {
    return sendLivestreamEmails(setMessage);
  }

  useEffect(() => {
    getConfigRecords().then(setConfig);
  }, []);
  return (
    <article data-theme="form">
      {user ? (
        <ConfigModal config={config} onSubmit={updateConfig} onSendEmails={sendEmails} />
      ) : (
        <LoginModal onLogin={setUser} />
      )}
      <Modal open={message}>
        <Modal.Content>
          <h2> </h2>
          <p>{message}</p>
          <Modal.Actions>
            <Button primary content="Ok" onClick={() => setMessage(null)} />
          </Modal.Actions>
        </Modal.Content>
      </Modal>
    </article>
  );
}

function VideoPreview({ src }) {
  return (
    <div
      style={{
        position: 'fixed',
        width: 'calc(50vw - 200px + 1rem)',
        right: 0,
        top: 0,
        display: 'flex',
        justifyContent: 'stretch',
        alignItems: 'center',
        height: '100%',
        padding: '2rem',
        boxSizing: 'border-box',
      }}
    >
      <iframe
        src={src}
        style={{ aspectRatio: '16 / 9', borderRadius: '6px', border: 'none' }}
        width="100%"
        scrolling="no"
        allowFullScreen
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      />
    </div>
  );
}
